import './auth-loader.css'

function AuthLoader() {
    return (
        <div className='rays'>
        </div>
    )

}

export default AuthLoader;