import './unauthorized.css'

function NoAuth() {
    return (
        <div className='noauth'>
            You are not authorized to view this content...
        </div>
    )
}

export default NoAuth;